//const axios = require('axios');



/**
***  jQuery .is() -> JavaScript  */
const matches = function( el, selector ) {
  return ( el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector ).call( el, selector );
};

// Elements
const referrer = document.referrer, // リファラー（ページの遷移元）
      hostName = location.protocol + '//' + window.location.hostname, // ホストURL
      regStr = '^' + hostName + '?', // 正規表現
      regExp = new RegExp(regStr, 'i');

// Close objects
const closeObj = '#js-close, #js-notify-wrapper';



// DOMContentLoaded
document.addEventListener('DOMContentLoaded', function(){

  if( ! regExp.test(referrer) ){

    // 非同期通信
    axios.get('/notify-window/ajax-notify-window.php')
    .then( function(res) {
      if( res.data === false ){ return; };
      // body 最後の子要素の後にモーダルウィンドウの DOM を記述する
      document.body.insertAdjacentHTML( 'beforeend', res.data );
      return;
    });


    // モーダルウインドウを閉じる処理
    document.addEventListener('click', function(e){
      e.stopPropagation();
      // モーダルウインドウの要素を取得
      const notifyWrapper = document.getElementById('js-notify-wrapper');
      if ( matches( e.target, closeObj ) ) {
        notifyWrapper.parentNode.removeChild(notifyWrapper);
      };
    });

  }

});
